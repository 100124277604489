.wrapper {
    background-color: var(--azhb);
    padding: 20px;
    border-radius: 25px 25px 25px 25px;
    transition: 0.5s;
}
.icon {
    position: absolute;
    top: 0;
    left: calc(50% - 20px);
    height: 20px;
    width: 20px;
}

.icon_close {
    position: absolute;
    top: 5px;
    right: 10px;
    height: 20px;
    width: 20px;
}

.naming {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
