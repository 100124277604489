.menu {
    border-radius: 25px 0 0 25px;
    padding: 10px;
    position: fixed;
    right: 0;
    top: 50px;
    z-index: 99;
    width: 320px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    transition: 0.5s;
    transform: translateX(100%);
}
.menu_active {
    transform: translateX(0%);
}
.menu-list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}
.menu-list a {
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 900;
}


.filter_container_cat {
    height: 220px;
    overflow: hidden;
}

.filter_features_button {
    margin-top: 7px;
}

@media (max-width: 2560px) {
    .menu-btn {
        width: 40px;
        height: 40px;
        position: absolute;
        right: 320px;
        top: 15px;
        z-index: 100;
    }
}

@media (max-width: 770px) {
    .menu-btn {
        width: 25px;
        height: 25px;
        position: absolute;
        right: 320px;
        top: 15px;
        z-index: 100;
    }
}
