.container_navbar {
    display: flex;
    width: 100vw;
    background-color: var(--azhb);
}

.logo_navbar {
    width: 150px;
    padding-left: 25px;
}

.topnav {
    overflow: hidden;
    background-color: var(--azhb);
    width: calc(100vw - 150px);
}

.topnav a {
    float: right;
    display: block;
    color: var(--black);
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

.topnav a:hover {
    background-color: var(--azhb-hover);
    color: black;
}

.active {
    background-color: var(--azhb-active);
    color: white;
}

.topnav .fa_icon {
    display: none;
}

@media screen and (max-width: 600px) {
    .topnav a:not(:first-child) {display: none;}
    .topnav a.fa_icon {
        float: right;
        display: block;
    }
}

@media screen and (max-width: 600px) {
    .topnav.responsive {position: relative;}
    .topnav.responsive .fa_icon {
        position: absolute;
        right: 0;
        top: 0;
    }
    .topnav.responsive a {
        float: none;
        display: block;
        text-align: left;
    }
}


.menu_item {
    text-align: left;
    width: 100px;
}
