@media (max-width: 2560px) {
    .counter_wrapper {
        display: flex;
        flex-direction: row;
    }

    .counter_item {
        width: calc(100vw / 7);
        height: 90px;
    }
}

@media (max-width: 770px) {
    .counter_wrapper {
        display: flex;
        flex-direction: column;
        width: 100vw;
    }

    .counter_item {
        width: 100vw;
        height: 60px;
    }
}
