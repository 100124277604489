@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');
.parent {
    display: flex;
    flex-direction: column;
}

.div1 {
    margin-bottom: 3vh;
}
.div2 {
    margin-top: 2vh;
    margin-bottom: 2vh;
    margin-left: 2vw;
    font-size: 18px;
}
.div3 {
    margin-left: 2vw;
    margin-bottom: 2vh;
    font-size: 18px;
}
.div4 {
    margin-top: 3vh;
    margin-left: 2vw;
}

.title_landing {
    font-family: "Inter", serif;
    font-size: 30px;
    text-align: left;
    font-weight: 500;
}
