body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;

}

.loader_map {
    width: 100vw;
    height: 100vh;
    padding-top: calc(50vh - 100px);
    padding-left: calc(50vw - 100px);
}

.container_for_features {
    width: 100%;
}
a
{
    text-decoration: none;
    color: #232323;

    transition: color 0.3s ease;
}

a:hover
{
    color: tomato;
}

.container_for_map {
    width: calc(100vw - 107px); /* установите нужное значение ширины */
    transition: width 0.5s ease; /* добавьте анимацию перехода */
}
.container_for_table {
    width: calc(100vw);
    height: calc(100vh - 107px);
    align-items: center;
}

.container_for_object_info_desktop{
    width: 320px;
    position: absolute;
    top: 100px;
    left: 0;
}

.container_for_object_info_mobile {
    position: absolute;
    width: 100%;
    bottom: 127px;
}

.container_for_filter{
    padding: 20px;
    width: 320px;
    height: calc(100vh - 177px);
    overflow-y: auto;

}

.object_title{
    width: 320px;
}

.h1_title{
    font-size: 22px;
    text-align: center;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
}

.h3_title {
    font-size: 20px;
    padding: 10px;
}

.h2_title {
    font-size: 18px;
    padding: 10px;
}

.close_title {
    padding: 10px;
    background-color: var(--nav-bg);
    width: 150px;
    border-radius: 3px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.close_filter {
    font-size: 16px;
    width: 150px;
    text-align: center;
    margin-top: auto;
    background-color: var(--nav-bg);
    padding: 5px;
    border-radius: 3px;
    margin-bottom: 0;
}

.scroll-table-body {
    height: calc(100vh - 127px);
    overflow-x: auto;
    margin-top: 0;
    width: calc(100vw);
    margin-bottom: 20px;
}
.scroll-table table {
    width: calc(100vw);
    table-layout: fixed;
    border: none;
    border-spacing: 0;
}
.scroll-table thead th {
    font-weight: bold;
    text-align: left;
    border: none;
    padding: 10px 15px;
    background: #d8d8d8;
    font-size: 14px;
}
.scroll-table tbody td {
    text-align: left;

    padding: 10px 15px;
    font-size: 14px;
    vertical-align: top;
}
.scroll-table tbody tr:nth-child(even){
    background: #f3f3f3;
}


#menuToggle
{
    right: 0;
    display: block;
    position: absolute;
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
}

#menuToggle input
{
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: 0px;
    left: 0px;
    cursor: pointer;
    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */
    -webkit-touch-callout: none;
}


#menuToggle span
{
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;

    background: black;
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0;

    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
    background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
    opacity 0.55s ease;
}

#menuToggle span:first-child
{
    transform-origin: 0 0;
}

#menuToggle span:nth-last-child(2)
{
    transform-origin: 0 100%;
}


#menuToggle input:checked ~ span
{
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #232323;
}

#menuToggle input:checked ~ span:nth-last-child(3)
{
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2)
{
    opacity: 1;
    transform: rotate(-45deg) translate(0, -1px);
}

#menu
{
    /*position: absolute;*/

    /*height: calc(100vh - 177px);*/
    /*padding: 20px 20px 20px;*/
    /*top: 50px;*/
    /*right: -320px;*/
    background: #DBEAF6;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0 0;
    transform: translate(100%, 0);

    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

#menu li
{
    padding: 5px 0;
    font-size: 22px;
    overflow-y: scroll;
    overflow-x: hidden;
}

#menuToggle input:checked ~ ul
{
    transform: none;
    opacity: 1;

}

@media screen and (max-width: 768px) {
    #menu {
        transform: none;
        opacity: 0;

        transition: opacity 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    }
}

@media (min-height: 768px) {
    .region {
        height: 35vh;
        overflow-y: scroll;
        font-size: 17px;
    }
    .region::-webkit-scrollbar {
        width: 10px;
    }
    .region::-webkit-scrollbar-track {
        background-color: darkgrey;
    }
    .region::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 1);
    }
}

@media (max-height: 768px) {
    .region {
        height: 15vh;
        overflow-y: scroll;
        font-size: 17px;
    }
    .region::-webkit-scrollbar {
        width: 10px;
    }
    .region::-webkit-scrollbar-track {
        background-color: darkgrey;
    }
    .region::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }
}


.comment_input {
    margin-top: 2vh;
    margin-bottom: 1vh;
    background-color: white;
    height: 30px;
    width: 250px;
}

.withMargin {
    margin-top: auto;
}

@media (max-width: 2560px) {
    .container_for_map_info {
        width: 100vw;
        justify-content: space-between;
        background-color: #DBEAF6;
    }

}

@media (max-width: 770px) {
    .container_for_map_info {
        width: 100vw;
        height: calc(7 * 60px);
        justify-content: space-between;
        background-color: #DBEAF6;
    }
}


.cat_prop {
    width: 250px;
    font-size: 17px;
    background-color: #DBEAF6;
}

.cat_prop_p {
    text-align: center;
    color: white;
}

.cat_prop_p_number {
    color: white;
    font-size: 22px;
    margin-top: auto;
    text-align: center;
}
