.modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
}

.modal.active {
    opacity: 1;
    pointer-events: all;
}

.modal__content {
    font-size: 20px;
    text-align: center;
    border-radius: 10px;
    background-color: var(--white);
    height: 200px;
    width: 450px;
    padding: 25px;
    display: flex;
    align-items: center;
    justify-items: center;
}

.modal__text {
    margin-left: auto;
    margin-right: auto;
}
