/* Footer */

.footer {
    margin-top: auto;
    padding: 60px 0 50px;
    background-color: var(--footer-bg);
    color: var(--footer-text);
}

.footer__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 27px;
}

.social {
    display: flex;
    column-gap: 30px;
    align-items: center;
}

.copyright {
    font-size: 16px;
}

.copyright p + p {
    margin-top: 0.5em;
}
